<template>
    <div class="table-responsive">
        <table class="table cart-recap">
            <thead>
                <tr>
                    <th class="cart-product-remove" v-if="!readonly">&nbsp;</th>
                    <th class="cart-product-thumbnail">&nbsp;</th>
                    <th class="cart-product-name">{{ $t("shop.activities.activity") }}</th>
                    <th class="cart-product-price">{{ $t("shop.activities.unit-price") }}</th>
                    <th class="cart-product-quantity">{{ $t("shop.activities.numberOfPersons") }}</th>
                    <th class="cart-product-subtotal">{{ $t("shop.products.total") }}</th>
                </tr>
            </thead>
            <tbody>
                <CartActivity v-for="(cartProduct, index) in products" :key="index + '-cart-product'" :cartProduct="cartProduct" :readonly="readonly"></CartActivity>
            </tbody>
        </table>
    </div>
</template>

<style>

/* We set font size of the header */
.cart-recap th {
    font-size:14px;
}

/* We hide thumbnail for smaller viewport */
@media (max-width: 767.98px) {
    .cart-recap .cart-product-thumbnail {
        display: none;
    }
}

/* We hide price for smaller viewport */
@media (max-width: 575.98px) {
    .cart-recap .cart-product-price {
        display: none;
    }
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import CartActivity from './CartActivity.vue';

@Component({
    components: {
        CartActivity
    },
    computed: {},
})
export default class Activities extends Vue {

    @Prop({
        type: Array,
        required: true
    }) readonly products!: any[]

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    }) readonly readonly!: boolean

}

</script>