<template>
    <tr class="cart_item" ref="cartProduct">
        <td class="cart-product-remove" v-if="!readonly">
            <button class="button button-dark button-rounded ladda-button product-remove" data-style="zoom-in" @click="onRemoveClick" :title="$t('shop.product.remove-button_title')"><i class="icon-trash2 m-0"></i></button>
        </td>

        <td class="cart-product-thumbnail">
            <div><img :src="thumbnailURL" :alt="product.title"></div>
        </td>

        <td class="cart-product-name">
            <div class="title">{{product.title}}</div>
            <div class="subtitle" v-if="product.subtitle && product.subtitle != ''">{{product.subtitle}}</div>
            <div>{{ formatDate(cartProduct.startDate) }}</div>
            <div>{{ formatDateTime(cartProduct.startDate) }} - {{ formatDateTime(addMinutesToDate(new Date(cartProduct.startDate), product.duration)) }}</div>
        </td>

        <td class="cart-product-price">
            <span class="amount">{{ formatPriceAmount(product.pricePerPerson) }}</span>
        </td>

        <td class="cart-product-quantity">
            <span>{{ cartProduct.numberOfPersons }}</span>
        </td>

        <td class="cart-product-subtotal">
            <span class="amount">{{ formatPriceAmount(cartProduct.amount) }}</span>
        </td>
    </tr>
</template>

<style>

/* We set the size of the picture */
td.cart-product-thumbnail > div {
    display: block;
    width: 64px;
    height: 95px;
}

/* We set the size of the picture */
td.cart-product-thumbnail > div img {
    height: 95px;
    width:auto;
    border: 0px;
    margin-right:auto;
    margin-left:auto;
}

/* We set default style for product name cell */
td.cart-product-name {
    font-weight: 700;
    font-size: 13px;
    color: var(--body-text-color);
}

/* We set the font size and vertical alignement of table cell */
tr.cart_item td {
    font-size: 14px;
    vertical-align: middle;
}

/* We adjust the style of the subtitle in product name */
td.cart-product-name .subtitle {
    font-weight: 500;
    font-style: italic;
}

/* We remove some space for the remove button and subtotal */
@media (max-width: 767.98px) {
    tr.cart_item td.cart-product-remove, .cart td.cart-product-subtotal {
        padding:0px !important;
    }
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import { formatDay, FormatDateType, formatTime, FromLocalToUTC, formatDateForInput, FromUTCToLocal } from '@igotweb-node-api-utils/formatter';
import { addMinutes } from '@igotweb-node-api-utils/date';
import { languagesTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
    components: {
        
    },
    computed: {},
})
export default class CartActivity extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly cartProduct!: any

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    }) readonly readonly!: boolean

    get product() {
        return this.cartProduct.activity;
    }

    get currentLanguageCode() {
        return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    }

    formatDate = (date:Date) => {
        return formatDay(date, this.currentLanguageCode, {type: FormatDateType.LONG, isUTC:true});
    }

    formatDateTime = (date:Date) => {
        return formatTime(date, this.currentLanguageCode, {isUTC:true});
    }

    addMinutesToDate = (date:Date, minutes:number) => {
        return addMinutes(date, minutes);
    }

    laddaSubmit:Ladda.LaddaButton|null = null;

    mounted() {
        if(!this.readonly) {
            // @ts-ignore
            var button:HTMLButtonElement|null = this.$refs.cartProduct.querySelector( 'button.ladda-button' );
            this.laddaSubmit = Ladda.create(button!);
        }
    }

    get thumbnailURL() {
        return this.$shop.product.getThumbnailsURLs(this.cartProduct, require('@public/img/vigneron-online/activity-shape.jpeg'))[0];
    }

    onRemoveClick(evt:Event) {
        evt.preventDefault();

        this.laddaSubmit!.start();
        this.$shop.cart.removeActivity(this.cartProduct.activity).then((response:any) => {
            this.laddaSubmit!.stop();
        });
    }

}

</script>